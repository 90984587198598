import { AppContext } from 'App';
import ArcPlcBaseAcreWorkspace from 'components/ArcPlc/ArcPlcBaseAcreWorkspace';
import ArcPlcCountyBaseAcreWorkspace from 'components/ArcPlc/ArcPlcCountyBaseAcreWorkspace';
import ArcPlcCountyListWorkspace from 'components/ArcPlc/ArcPlcCountyListWorkspace';
import ArcPlcCountyWorkspace from 'components/ArcPlc/ArcPlcCountyWorkspace';
import TransactionImportWorkspace from 'components/Broker/TransactionImportWorkspace';
import BudgetCountyPracticeWorkspace from 'components/Budget/BudgetCountyPracticeWorkspace';
import BudgetListWorkspace from 'components/Budget/BudgetListWorkspace';
import BudgetWorkspace from 'components/Budget/BudgetWorkspace';
import EquipmentInventoryWorkspace from 'components/Financial/EquipmentInventoryWorkspace';
import FinancialDocumentsWorkspace from 'components/Financial/FinancialDocumentsWorkspace';
import FinancialScorecardWorkspace from 'components/Financial/FinancialScorecardWorkspace';
import FinancialSummaryWorkspace from 'components/Financial/FinancialSummaryWorkspace';
import InterviewPage from 'components/Financial/InterviewPage';
import LoanOriginationMemoPage from 'components/Financial/LoanOriginationMemoPage';
import BalanceSheetWorkspace from 'components/Financial/forms/BalanceSheetWorkspace';
import DebtScheduleWorkspace from 'components/Financial/forms/DebtScheduleWorkspace';
import IncomeStatementWorkspace from 'components/Financial/forms/IncomeStatementWorkspace';
import ScheduleFReport from 'components/Financial/forms/ScheduleFReport';
import ScheduleFWorkspace from 'components/Financial/forms/ScheduleFWorkspace';
import InsuranceCountyPracticeWorkspace from 'components/Insurance/InsuranceCountyPracticeWorkspace';
import InsuranceScenarioWorkspace from 'components/Insurance/InsuranceScenarioWorkspace';
import InsuranceSummaryWorkspace from 'components/Insurance/InsuranceSummaryWorkspace';
import LoanBookListWorkspace from 'components/Lender/LoanBook/LoanBookListWorkspace';
import BuyerWorkspace from 'components/Marketing/BuyerWorkspace';
import ClientWorkspace from 'components/Marketing/ClientWorkspace';
import RiskSummaryBroker from 'components/Marketing/RiskSummaryBroker';
import RiskSummaryWorkspace from 'components/Marketing/RiskSummaryWorkspace';
import SalesWorkspace from 'components/Marketing/SalesWorkspace';
import MemberAccountWorkspace from 'components/Member/MemberAccountWorkspace';
import Portfolio from 'components/Portfolio/Portfolio';
import AcresMapReport from 'components/Reports/Vault/AcresMapReport';
import FarmTractFieldReport from 'components/Reports/Vault/FarmTractFieldReport';
import FarmsReport from 'components/Reports/Vault/FarmsReport';
import InsuredRevenueByFarmReport from 'components/Reports/Vault/InsuredRevenueByFarm';
import ChangeAuditReport from 'components/System/ChangeAuditReport';
import UserGrantItemWorkspace from 'components/UserGrants/UserGrantItemWorkspace';
import UserGrantListWorkspace from 'components/UserGrants/UserGrantListWorkspace';
import UserGrantWorkspace from 'components/UserGrants/UserGrantWorkspace';
import FarmBaseAcreWorkspace from 'components/Vault/Farm/FarmBaseAcreWorkspace';
import FarmDetailWorkspace from 'components/Vault/Farm/FarmDetailWorkspace';
import FarmFieldCropWorkspace from 'components/Vault/Farm/FarmFieldCropWorkspace';
import FarmListWorkspace from 'components/Vault/Farm/FarmListWorkspace';
import FieldWorkspace from 'components/Vault/Farm/FieldWorkspace';
import VaultCurrentWorkspace from 'components/Vault/Farm/VaultCurrentWorkspace';
import VaultPotentialWorkspace from 'components/Vault/Farm/VaultPotentialWorkspace';
import VaultPrintWorkspace from 'components/Vault/Farm/VaultPrintWorkspace';
import { default as ArcPlcWorkspace, default as EmptyWorkspace } from 'components/Workspaces/EmptyWorkspace';
import { useContext, useMemo } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import WorkspaceContainer from '../Workspaces/WorkspaceContainer';
import ErrorPanel from './ErrorPanel';
import NavigationMenu from './NavigationMenu';
import NotFound from './NotFound';

export default function NavigationRoutes({
}) {
    const { globalState } = useContext(AppContext);

    const navigationMenu = useMemo(() => {
        return <NavigationMenu />
    }, [
        globalState.user_role,
        globalState.producer_token, globalState.agent_token
    ]);

    const router = createBrowserRouter([
        // Catch-all route for undefined paths
        {
            path: "*",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <NotFound />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Page Not Found' />
                </WorkspaceContainer>
        },
        {
            path: "/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ArcPlcWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Tilley' />
                </WorkspaceContainer>
        },

        // ---

        {
            path: "/vault",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <VaultCurrentWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },
        {
            path: "/vault/potential",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <VaultPotentialWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Potential' />
                </WorkspaceContainer>
        },
        {
            path: "/vault/print/:crop_year",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <VaultPrintWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },
        {
            path: "/vault/:crop_year/:location_state_name/:location_county_name",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FarmListWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },
        {
            path: "/vault/:crop_year/:location_state_name/:location_county_name/:producer_farm_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FarmDetailWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },
        {
            path: "/vault/:crop_year/:location_state_name/:location_county_name/:producer_farm_id/:producer_farm_field_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FieldWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },
        {
            path: "/vault/:crop_year/:location_state_name/:location_county_name/:producer_farm_id/baseAcre/:producer_farm_base_acre_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FarmBaseAcreWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },
        {
            path: "/vault/:crop_year/:location_state_name/:location_county_name/:producer_farm_id/:producer_farm_field_id/:producer_farm_field_crop_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FarmFieldCropWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },

        // ---

        {
            path: "/vault/documents",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FinancialDocumentsWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Documents' />
                </WorkspaceContainer>
        },

        // ---

        {
            path: "/budget",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <BudgetListWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Budget' />
                </WorkspaceContainer>
        },
        {
            path: "/budget/:budget_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <BudgetWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Budget' />
                </WorkspaceContainer>
        },
        {
            path: "/budget/:budget_id/:budget_county_practice_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <BudgetCountyPracticeWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Budget' />
                </WorkspaceContainer>
        },

        // ---

        {
            path: "/arcplc",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ArcPlcCountyListWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='ARC/PLC' />
                </WorkspaceContainer>
        },
        {
            path: "/arcplc/:crop_year/:location_state_name/:location_county_name",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ArcPlcCountyWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='ARC/PLC' />
                </WorkspaceContainer>
        },
        {
            path: "/arcplc/:crop_year/:location_state_name/:location_county_name/:commodity_name",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ArcPlcCountyBaseAcreWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='ARC/PLC' />
                </WorkspaceContainer>
        },
        {
            path: "/arcplc/:crop_year/:location_state_name/:location_county_name/:commodity_name/:producer_farm_base_acres_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ArcPlcBaseAcreWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='ARC/PLC' />
                </WorkspaceContainer>
        },

        // ---

        {
            path: "/insurance",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <InsuranceSummaryWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Insurance' />
                </WorkspaceContainer>
        },
        {
            path: "/insurance/:insurance_scenario_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <InsuranceCountyPracticeWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Insurance' />
                </WorkspaceContainer>
        },
        {
            path: "/insurance/:insurance_scenario_id/:state_name/:county_name/:commodity_name/:type_name/:practice_name",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <InsuranceScenarioWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Insurance' />
                </WorkspaceContainer>
        },

        // ---

        {
            path: "/account",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <Portfolio />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Members' />
                </WorkspaceContainer>
        },
        {
            path: "/account/:member_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <MemberAccountWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Manage Account' />
                </WorkspaceContainer>
        },

        // ---

        {
            path: "/financial",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <EmptyWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Financial Analysis' />
                </WorkspaceContainer>
        },
        {
            path: "/financial/interview",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <InterviewPage />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Interview' />
                </WorkspaceContainer>
        },
        {
            path: "/financial/equipment",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <EquipmentInventoryWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Equipment Inventory' />
                </WorkspaceContainer>
        },
        {
            path: "/financial/documents",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FinancialDocumentsWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Financial Documents' />
                </WorkspaceContainer>
        },
        {
            path: "/financial/balance_sheet",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <BalanceSheetWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Balance Sheet' />
                </WorkspaceContainer>
        },
        {
            path: "/financial/income_statement",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <IncomeStatementWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Income Statement' />
                </WorkspaceContainer>
        },

        {
            path: "/financial/schedule_f",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ScheduleFWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Schedule F' />
                </WorkspaceContainer>
        },
        {
            path: "/financial/schedule_f_report",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ScheduleFReport />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Schedule F Report' />
                </WorkspaceContainer>
        },

        {
            path: "/financial/debt_schedule",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <DebtScheduleWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Debt Schedule' />
                </WorkspaceContainer>
        },

        {
            path: "/financial/summary",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FinancialSummaryWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Financial Summary' />
                </WorkspaceContainer>
        },
        {
            path: "/financial/scorecard",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FinancialScorecardWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Financial Score Card' />
                </WorkspaceContainer>
        },


        {
            path: "/reports/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <EmptyWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Report' />
                </WorkspaceContainer>
        },
        {
            path: "/reports/vault/FarmReport/:crop_year",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <EmptyWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Report' />
                </WorkspaceContainer>
        },
        {
            path: "/reports/vault/FarmsReport/:crop_year",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FarmsReport />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Report' />
                </WorkspaceContainer>
        },
        {
            path: "/reports/vault/FarmTractFieldReport/:crop_year",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <FarmTractFieldReport />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Report' />
                </WorkspaceContainer>
        },
        {
            path: "/reports/vault/AcresMapReport/:crop_year",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <AcresMapReport />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Acres Map Report' />
                </WorkspaceContainer>
        },
        {
            // InsuredRevenueByFarmReport
            path: "/reports/vault/InsuredRevenueByFarmReport/:crop_year",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <InsuredRevenueByFarmReport />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Insured Revenue By Farm Report' />
                </WorkspaceContainer>
        },


        {
            path: "/lender/loan_book",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <LoanBookListWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Loan Book' />
                </WorkspaceContainer>
        },


        {
            path: "/lender/loan_origination_memo/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <LoanOriginationMemoPage />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Loan Origination Memo' />
                </WorkspaceContainer>
        },
        {
            path: "/lender/loan_book/loan_origination_memo/:producer_token/:year/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <LoanOriginationMemoPage />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Loan Origination Memo' />
                </WorkspaceContainer>
        },

        // ---

        {
            path: "/userGrant",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <UserGrantListWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Sharing' />
                </WorkspaceContainer>
        },
        {
            path: "/userGrant/:producer_token",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <UserGrantWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Sharing' />
                </WorkspaceContainer>
        },
        {
            path: "/userGrant/:producer_token/:user_grant_id",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <UserGrantItemWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Sharing' />
                </WorkspaceContainer>
        },

        // --- --- ---
        // Marketing

        {
            path: "/marketing/sales/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <SalesWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Sales' />
                </WorkspaceContainer>
        },
        {
            path: "/marketing/position_summary",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <RiskSummaryWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Position Summary' />
                </WorkspaceContainer>
        },
        {
            path: "/marketing/buyers/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <BuyerWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },

        // --- --- ---
        // --- Broker

        {
            path: "/broker/transactions/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <TransactionImportWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },
        {
            path: "/broker/position_summary/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <RiskSummaryBroker />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },
        {
            path: "/broker/position_summary/:producer_token/:defaultYear",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <RiskSummaryWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Position Summary' />
                </WorkspaceContainer>
        },
        {
            path: "/broker/position_summary/:producer_token/:defaultYear/:default_commodity_name",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <RiskSummaryWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Position Summary' />
                </WorkspaceContainer>
        },
        {
            path: "/broker/clients/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ClientWorkspace />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        },

        // --- --- ---
        // System (Admin)
        {
            path: "/system/change_audit/",
            element: <>
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ChangeAuditReport />
                </WorkspaceContainer>
            </>,
            errorElement:
                <WorkspaceContainer navMenu={navigationMenu}>
                    <ErrorPanel title='Farm Vault' />
                </WorkspaceContainer>
        }

        // ---
    ]);

    return (<>
        <RouterProvider router={router} />
    </>);
}
