import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'App';
import { Box, Button, Dialog, IconButton, Stack, useMediaQuery } from '@mui/material';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import { FarmVaultIcon, MemberManagementIcon } from 'components/Icons/Icons';
import { DataGrid } from '@mui/x-data-grid'
import { AddCircleOutline, EastOutlined } from '@mui/icons-material';
import { ClientDialog, DataDictionaryDialog } from './ClientDialog';
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar';
import {  _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { v4 } from 'uuid';
import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { useSnackbar } from 'notistack';

export default function ClientWorkspace() {
    const { globalState } = useContext(AppContext);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const [editingItem, setEditingItem] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    // ---

    const [userGrants, setUserGrants] = useState([]);
    const [selectedUserGrant, setSelectedUserGrant] = useState(null);
    useEffect(() => {
        const _loadUserGrants = async () => {
            const _userGrants = await UsersApiHelper.selectUserGrants(
                { user_id: globalState.agent_token },
                { user_role: globalState?.user_role, agent_token: globalState?.agent_token }
            );
            setUserGrants(_userGrants);
        };
        _loadUserGrants();
    }, []);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    useEffect(() => {
        const _loadMember = async () => {
            try {
                if (selectedUserGrant) {
                    // Get Member:
                    const _members = await UsersApiHelper.users_selectObject("Member", { producer_token: selectedUserGrant?.producer_token }, {});
                    if (_members?.length) {
                        setEditingItem(_members[0]);
                    } else {
                        setEditingItem(null);
                    }
                }
            } catch (err) {
                handleError(err);
            }
        }
        _loadMember();
    }, [selectedUserGrant]);

    // ---

    const columns = useMemo(() => [
        {
            field: 'producer_description',
            headerName: 'Client',
            minWidth: 200,
            flex: 3
        },
        {
            field: '',
            headerName: ' ',
            renderCell: (params) => (
                <>
                    <Stack direction='row'>
                        <IconButton onClick={() => setSelectedUserGrant(params.row)}>
                            <MemberManagementIcon />
                        </IconButton>
                    </Stack>
                </>
            ),
            flex: 1
        }
    ], []);

    const handleChange = async (account) => {
        const api = new ApiHelper({
            baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
            config: {},
            sessionManager: _sessionManager,
            // No caching:
            _instance: null
        });
        await api.callPost("marketing/Member", account, globalState);
    }

    const handleAddNewClick = () => {
        setEditingItem({
            id: v4(),
            is_active: true,
        });
    }

    const handleDialogClose = async () => {
        setEditingItem(null);
        setSelectedUserGrant(null);
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Clients'
                breadCrumbs={[{
                    title: 'Marketing',
                    icon: <FarmVaultIcon />
                }]}
            />

            <DataGrid
                rows={userGrants || []}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: isMobile ? 5 : 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                autoHeight
            />

            <Dialog open={Boolean(editingItem != null)}>
                <ClientDialog member={editingItem}
                    onChange={handleChange} onClose={() => handleDialogClose()} />
            </Dialog>

            <WorkspaceActionBar content={<>
                <Box>
                    <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={handleAddNewClick}
                    >
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Client
                    </Button>
                </Box>
            </>} />

        </>
    );
}