import { USER_ROLE_PRODUCER } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper';
import { useTheme } from '@emotion/react';
import CalendarMonthOutlined from '@mui/icons-material/CalendarMonthOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import EastOutlined from '@mui/icons-material/EastOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import ManageAccountsTwoTone from '@mui/icons-material/ManageAccountsTwoTone';
import Person from '@mui/icons-material/Person';
import { Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, Stack, styled, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { AppContext } from 'App';
import { AipPassApiHelper, UsersApiHelper } from 'api/ApiHelper';
import { Auth } from 'aws-amplify';
import { propUpdateReducer } from 'components/Core/propUpdateReducer';
import { ArcPlcIcon, BudgetIcon, EquipmentIcon, FarmAcresIcon, FarmVaultIcon, FinancialDocumentsIcon, FinancialIcon, FinancialSummaryIcon, InsuranceIcon, InterviewIcon, LoanOriginationIcon, MapIcon, MarketingIcon, MemberManagementIcon, SecurityIcon } from 'components/Icons/Icons';
import { USER_ROLE_ADMIN, USER_ROLE_AGENT } from 'core/useSession';
import { useContext, useEffect, useReducer, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

export const NAVIGATION_MENU_DRAWER_WIDTH = 300;

export const MODULE_FARM_VAULT = "vault";
export const MODULE_BUDGET = "budget";
export const MODULE_ARC_PLC = "arc_plc";
export const MODULE_INSURANCE = "insurance";
export const MODULE_FINANCIAL = "financial";
export const MODULE_REPORTS = "reports";
export const MODULE_MARKETING = "marketing";
// Not a genuine SMOCI module; but used to render UI for Broker group (globalState?.is_broker).
export const MODULE_BROKER = "broker";
export const MODULE_SYSTEM = "system";

export const SECTION_FINANCIAL_SCHEDULE_F = "financial/schedule_f"

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default function NavigationMenu({
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const theme = useTheme();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isPrint = useMediaQuery('print');

    // ---

    const [myUser, setMyUser] = useState();
    const loadMyUser = async () => {
        const session = await Auth.currentSession();
        const token = session.getIdToken();
        setMyUser({
            name: token?.payload?.['cognito:username']
        });
    }
    useEffect(() => {
        loadMyUser();
    }, [globalState?.user_role])
    // ---

    const [activeProducer, setActiveProducer] = useState();
    const selectActiveProducer = async () => {
        let _producer = await UsersApiHelper.users_selectObject("Member", { producer_token: globalState.producer_token }, globalState);

        if (_producer?.length) {
            setActiveProducer(_producer[0]);
        }
    };

    useEffect(() => {
        if (globalState?.producer_token) {
            selectActiveProducer();
        } else {
            setActiveProducer(null);
        }
    }, [globalState?.producer_token])

    const EXPIRATION_THRESHOLD_DAYS = 30;
    const getExpirationMessageThresholdDate = () => {
        const expirationDate = (new Date()).addDays(EXPIRATION_THRESHOLD_DAYS);
        return expirationDate;
    }

    const calculateIsExpired = (_subscription) => {
        const _subscriptionStatus = _subscription || subscriptionStatus;
        return Boolean((new Date(_subscriptionStatus?.reNewalDate)) <= (new Date()));
    }

    const calculateIsExpiringSoon = (_subscription) => {
        const _subscriptionStatus = _subscription || subscriptionStatus;
        return Boolean((new Date(_subscriptionStatus?.reNewalDate)) <= getExpirationMessageThresholdDate());
    }

    const PAYMENT_TIMESTAMP_THRESHOLD_MINUTES = 1;
    const PAYMENT_SNOOZE_THRESHOLD_MINUTES = 60 * 8;
    const calculatePaymentTimestampDifference = () => {
        try {
            return (Boolean(globalState?.subscription_payment_timestamp) && ((new Date()).getTime() - globalState?.subscription_payment_timestamp) / 1000 / 60) || 0;
        } catch {
            return 0;
        }
    }
    const calculatePaymentSnoozeTimestampDifference = () => {
        try {
            return (Boolean(globalState?.subscription_snooze_timestamp) && ((new Date()).getTime() - globalState?.subscription_snooze_timestamp) / 1000 / 60) || 0;
        } catch {
            return 0;
        }
    }

    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [doShowPaymentDialog, setDoShowPaymentDialog] = useState(false);
    const loadSubscriptionStatus = async () => {
        if (activeProducer?.subscription_is_posted) {
            const subscription = await UsersApiHelper.selectMemberSubscription(activeProducer, globalState);
            setSubscriptionStatus(subscription);

            if (calculateIsExpired(subscription)) {
                if (calculatePaymentTimestampDifference() == 0 || calculatePaymentTimestampDifference() > PAYMENT_TIMESTAMP_THRESHOLD_MINUTES) {
                    setDoShowPaymentDialog(true);
                }
            } else if (calculateIsExpiringSoon(subscription)) {
                if (calculatePaymentTimestampDifference() > PAYMENT_TIMESTAMP_THRESHOLD_MINUTES
                    || calculatePaymentSnoozeTimestampDifference() == 0 || calculatePaymentSnoozeTimestampDifference() > PAYMENT_SNOOZE_THRESHOLD_MINUTES
                ) {
                    setDoShowPaymentDialog(true);
                }
            }
        } else {
            alert("No active subscription is found!  Please contact Tilley customer service.");
        }
    }

    // useEffect for initial load + and every time path changes.
    useEffect(() => {
        if (globalState?.producer_token) {
            if (activeProducer?.subscription_is_posted || activeProducer?.subscription_is_posted == false) {
                loadSubscriptionStatus();
            }
        }
    }, [pathname, activeProducer?.id, activeProducer?.subscription_is_posted, globalState?.producer_token])

    const handleSnoozePaymentDialog = async () => {
        setDoShowPaymentDialog(false);
        globalDispatch({
            type: 'update',
            payload: {
                key: 'subscription_snooze_timestamp',
                value: (new Date()).getTime()
            },
        });
    }

    const handleClosePaymentDialog = async () => {
        setDoShowPaymentDialog(false);
        globalDispatch({
            type: 'update',
            payload: {
                key: 'subscription_payment_timestamp',
                value: (new Date()).getTime()
            },
        });
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const [isLoadingPaymentLink, setIsLoadingPaymentLink] = useState(false);
    const handlePaymentLinkClick = async () => {
        setIsLoadingPaymentLink(true);

        UsersApiHelper.selectMemberPaymentSessionUrl(activeProducer, globalState)
            .then((url) => {
                openInNewTab(url);
                setDoShowPaymentDialog(false);
                setIsLoadingPaymentLink(false);

                globalDispatch({
                    type: 'update',
                    payload: {
                        key: 'subscription_payment_timestamp',
                        value: (new Date()).getTime()
                    },
                });
            })
            .catch(err => {
                console.warn(err);
                enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                setDoShowPaymentDialog(false);
                setIsLoadingPaymentLink(false);
            });
    }

    // ---

    const [activeAgent, setActiveAgent] = useState();
    const selectActiveAgent = async () => {
        let _agent = await AipPassApiHelper.pass_selectObject("AgentTokens",
            { agent_token: globalState.agent_token },
            { agent_token: globalState.agent_token });
        if (_agent?.length) {
            setActiveAgent(_agent[0]);
        }
    };

    useEffect(() => {
        if (globalState?.user_role == USER_ROLE_AGENT && globalState?.agent_token) {
            selectActiveAgent();
        }
    }, [globalState?.agent_token])

    // ---

    const [userGrants, setUserGrants] = useState(null);
    const loadUserGrants = async () => {
        const { user_role, agent_token, producer_token } = globalState;
        const _userGrants = await UsersApiHelper.selectUserGrantItems(
            { user_role, agent_token, producer_token, is_active: true },
            globalState
        );
        setUserGrants(_userGrants);
    }

    useEffect(() => {
        if (globalState?.user_role == USER_ROLE_AGENT && globalState?.producer_token) {
            loadUserGrants();
        } else {
            setUserGrants([]);
        }
    }, [globalState?.user_role, globalState?.producer_token, globalState?.agent_token])

    // --- --- ---

    const handleActiveUserSignoutClick = (e) => {
        globalDispatch({
            type: 'setProducer',
            payload: {
                producer_token: null,
                agent_token: globalState?.agent_token
            },
        });

        navigate("/account");
    }

    const handleNavigate = async (path) => {
        navigate(path);
        if (isSmall) {
            handleDrawerClose();
        }

        if (!calculateIsModuleActive_Intern(MODULE_FARM_VAULT, path)) {
            setIsSubMenuOpen(MODULE_FARM_VAULT, false);
        }
        if (!calculateIsModuleActive_Intern(MODULE_FINANCIAL, path)) {
            setIsSubMenuOpen(MODULE_FINANCIAL, false);
        }
        if (!calculateIsModuleActive_Intern(MODULE_MARKETING, path)) {
            setIsSubMenuOpen(MODULE_MARKETING, false);
        }
    }

    const handleDrawerClose = () => {
        // setOpen(false);

        globalDispatch({
            type: 'update',
            payload: {
                key: 'isMenuOpen',
                value: false
            },
        });
    };

    const handleLogoutClick = () => {
        globalDispatch({ type: "logout" });
    }

    // ---

    const calculateIsModuleAllowed = (_module) => {
        if (!userGrants?.length) {
            return Boolean(globalState?.producer_token
                && (globalState?.user_role == USER_ROLE_PRODUCER || globalState?.user_role == USER_ROLE_ADMIN)
            );
        } else {
            const _userGrant = userGrants?.find(ug =>
                ug?.module == _module
            );
            if (!_userGrant) {
                return (
                    userGrants?.[0]?.is_master_read
                    || userGrants?.[0]?.is_master_write
                    // || userGrants?.[0]?.is_master_delete
                );
            } else {
                return (
                    _userGrant?.is_master_read
                    || _userGrant?.is_master_write
                    // || _userGrant?.is_master_delete

                    || _userGrant?.is_read
                    || _userGrant?.is_write
                    // || _userGrant?.is_delete
                );
            }
        }
    }

    // --- --- ---

    const calculateIsModuleActive = (_module) => {
        return calculateIsModuleActive_Intern(_module, pathname);
    }

    const calculateIsModuleActive_Intern = (_module, _pathname) => {
        if (pathname) {
            return matchPath(`/${_module}/*`, _pathname) ? true : false;
        }
    }

    const [subMenuOpenState, dispatchSubMenuOpenState] = useReducer(propUpdateReducer, {});
    const calculateIsSubMenuOpen = (key) => {
        return calculateIsModuleActive(key) || subMenuOpenState[key] || false;
    }

    const setIsSubMenuOpen = async (key, isOpen) => {
        dispatchSubMenuOpenState({
            type: 'update',
            payload: {
                key: key,
                value: isOpen
            },
        });

        if (isOpen) {
            if (key != MODULE_FARM_VAULT) {
                setIsSubMenuOpen(MODULE_FARM_VAULT, false);
            }
        }
    }

    const toggleIsSubMenuOpen = async (key) => {
        const isOpen = calculateIsSubMenuOpen(key);

        dispatchSubMenuOpenState({
            type: 'update',
            payload: {
                key: key,
                value: !isOpen
            },
        });
    }

    // --- --- ---

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    return (
        (!isPrint && <>
            <Drawer
                sx={{
                    width: NAVIGATION_MENU_DRAWER_WIDTH,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: NAVIGATION_MENU_DRAWER_WIDTH,
                        boxSizing: 'border-box',
                        border: 'none',
                    },
                    border: 'none',
                }}
                ModalProps={{ onBackdropClick: handleDrawerClose }}
                variant={isSmall || !globalState?.isMenuOpen ? "temporary" : "persistent"}
                anchor="left"
                open={globalState?.isMenuOpen}
            >
                <DrawerHeader sx={{ p: 0, m: 0 }}>
                    <Box display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', m: 2 }}
                    >
                        <a href="/">
                            <img src='/images/Tilley_logo_RGB_100.png' width='100' height='38' sx={{ left: 0, position: 'absolute', marginLeft: 0 }} />
                        </a>
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <List>
                    <ListItemButton onClick={() => toggleIsSubMenuOpen(MODULE_FARM_VAULT)} disabled={!calculateIsModuleAllowed(MODULE_FARM_VAULT)}>
                        <ListItemIcon>
                            <FarmVaultIcon />
                        </ListItemIcon>
                        <ListItemText primary='Farm Vault' />
                        {!calculateIsModuleActive(MODULE_FARM_VAULT) && (calculateIsSubMenuOpen(MODULE_FARM_VAULT) ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                    <Collapse in={calculateIsSubMenuOpen(MODULE_FARM_VAULT)}>
                        <List component="div" disablePadding>
                            <ListItemButton className={pathname == '/vault' ? 'active' : ''} onClick={() => handleNavigate(`/vault`)} disabled={!calculateIsModuleAllowed(MODULE_FARM_VAULT)}>
                                <ListItemIcon>
                                    <FarmAcresIcon />
                                </ListItemIcon>
                                <ListItemText primary='Planted Acres' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/vault/documents' ? 'active' : ''} onClick={() => handleNavigate(`/vault/documents`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialDocumentsIcon />
                                </ListItemIcon>
                                <ListItemText primary='Documents' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/vault/potential' ? 'active' : ''} onClick={() => handleNavigate(`/vault/potential`)} disabled={!calculateIsModuleAllowed(MODULE_FARM_VAULT)}>
                                <ListItemIcon>
                                    <FarmAcresIcon />
                                </ListItemIcon>
                                <ListItemText primary='Farm Potential' />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    <ListItemButton className={pathname == '/budget' ? 'active' : ''} onClick={() => handleNavigate(`/budget`)} disabled={!calculateIsModuleAllowed(MODULE_BUDGET)}>
                        <ListItemIcon>
                            <BudgetIcon />
                        </ListItemIcon>
                        <ListItemText primary='Budgets' />
                    </ListItemButton>
                    <ListItemButton className={pathname == '/arcplc' ? 'active' : ''} onClick={() => handleNavigate(`/arcplc`)} disabled={!calculateIsModuleAllowed(MODULE_ARC_PLC)}>
                        <ListItemIcon>
                            <ArcPlcIcon />
                        </ListItemIcon>
                        <ListItemText primary='ARC/PLC' />
                    </ListItemButton>
                    <ListItemButton className={pathname == '/insurance' ? 'active' : ''} onClick={() => handleNavigate(`/insurance`)} disabled={!calculateIsModuleAllowed(MODULE_INSURANCE)}>
                        <ListItemIcon>
                            <InsuranceIcon />
                        </ListItemIcon>
                        <ListItemText primary='Insurance' />
                    </ListItemButton>

                    <ListItemButton onClick={() => toggleIsSubMenuOpen(MODULE_FINANCIAL)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                        <ListItemIcon>
                            <FinancialIcon />
                        </ListItemIcon>
                        <ListItemText primary='Financial Analysis' />
                        {!calculateIsModuleActive(MODULE_FINANCIAL) && (calculateIsSubMenuOpen(MODULE_FINANCIAL) ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                    <Collapse in={calculateIsSubMenuOpen(MODULE_FINANCIAL)}>
                        <List component="div" disablePadding>
                            <ListItemButton className={pathname == '/financial/interview' ? 'active' : ''} onClick={() => handleNavigate(`/financial/interview`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <InterviewIcon />
                                </ListItemIcon>
                                <ListItemText primary='Interview' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/financial/equipment' ? 'active' : ''} onClick={() => handleNavigate(`/financial/equipment`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <EquipmentIcon />
                                </ListItemIcon>
                                <ListItemText primary='Equipment Inventory' />
                            </ListItemButton>


                            <ListItemButton className={pathname == '/financial/documents' ? 'active' : ''} onClick={() => handleNavigate(`/financial/documents`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialDocumentsIcon />
                                </ListItemIcon>
                                <ListItemText primary='Documents' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/financial/balance_sheet' ? 'active' : ''} onClick={() => handleNavigate(`/financial/balance_sheet`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialDocumentsIcon />
                                </ListItemIcon>
                                <ListItemText primary='Balance Sheet' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/financial/income_statement' ? 'active' : ''} onClick={() => handleNavigate(`/financial/income_statement`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialDocumentsIcon />
                                </ListItemIcon>
                                <ListItemText primary='Income Statement' />
                            </ListItemButton>

                            <ListItemButton className={pathname == '/financial/schedule_f' ? 'active' : ''} onClick={() => handleNavigate(`/financial/schedule_f`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialDocumentsIcon />
                                </ListItemIcon>
                                <ListItemText primary='Schedule F' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/financial/schedule_f_report' ? 'active' : ''} onClick={() => handleNavigate(`/financial/schedule_f_report`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialDocumentsIcon />
                                </ListItemIcon>
                                <ListItemText primary='Schedule F Report' />
                            </ListItemButton>

                            <ListItemButton className={pathname == '/financial/debt_schedule' ? 'active' : ''} onClick={() => handleNavigate(`/financial/debt_schedule`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialDocumentsIcon />
                                </ListItemIcon>
                                <ListItemText primary='Debt Schedule' />
                            </ListItemButton>

                            <ListItemButton className={pathname == '/financial/summary' ? 'active' : ''} onClick={() => handleNavigate(`/financial/summary`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialSummaryIcon />
                                </ListItemIcon>
                                <ListItemText primary='Summary' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/financial/scorecard' ? 'active' : ''} onClick={() => handleNavigate(`/financial/scorecard`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                                <ListItemIcon>
                                    <FinancialSummaryIcon />
                                </ListItemIcon>
                                <ListItemText primary='Score Card' />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    {Boolean(globalState.is_broker || globalState.user_role == USER_ROLE_ADMIN) && <>
                        <ListItemButton onClick={() => toggleIsSubMenuOpen(MODULE_MARKETING)}
                            disabled={!calculateIsModuleAllowed(MODULE_MARKETING)}
                        >
                            <ListItemIcon>
                                <MarketingIcon />
                            </ListItemIcon>
                            <ListItemText primary='Marketing' />
                            {!calculateIsModuleActive(MODULE_MARKETING) && (calculateIsSubMenuOpen(MODULE_MARKETING) ? <ExpandLess /> : <ExpandMore />)}
                        </ListItemButton>

                        <Collapse in={calculateIsSubMenuOpen(MODULE_MARKETING)}>
                            <List component="div" disablePadding>
                                <ListItemButton className={pathname == '/marketing/sales' ? 'active' : ''} onClick={() => handleNavigate(`/marketing/sales`)} disabled={!calculateIsModuleAllowed(MODULE_MARKETING)}>
                                    <ListItemIcon>
                                        <MarketingIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Sales' />
                                </ListItemButton>
                                <ListItemButton className={pathname == '/marketing/position_summary' ? 'active' : ''}
                                    onClick={() => handleNavigate(`/marketing/position_summary`)}
                                    disabled={!calculateIsModuleAllowed(MODULE_BROKER)
                                        || !(globalState.is_broker && globalState?.producer_token/*Only available if impersonating*/)
                                        || !globalState.is_admin}
                                >
                                    <ListItemIcon>
                                        <FinancialIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Position Summary' />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>}

                    {/* --- --- --- Broker */}

                    <ListItemButton onClick={() => toggleIsSubMenuOpen(MODULE_BROKER)}
                        disabled={!(calculateIsModuleAllowed(MODULE_BROKER) || globalState.is_broker)
                            || (globalState.is_broker && globalState?.producer_token/*Disable if impersonating*/)
                            || !globalState.is_admin}
                    >
                        <ListItemIcon>
                            <MarketingIcon />
                        </ListItemIcon>
                        <ListItemText primary='Broker' />
                        {!calculateIsModuleActive(MODULE_BROKER) && (calculateIsSubMenuOpen(MODULE_BROKER) ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>

                    <Collapse in={calculateIsSubMenuOpen(MODULE_BROKER) || (globalState.is_broker && !globalState?.producer_token)}>
                        <List component="div" disablePadding>
                            <ListItemButton className={pathname == '/broker/transactions' ? 'active' : ''} onClick={() => handleNavigate(`/broker/transactions`)}
                                disabled={!(calculateIsModuleAllowed(MODULE_BROKER) || globalState.is_broker)
                                    || (globalState.is_broker && globalState?.producer_token/*Disable if impersonating*/)
                                    || !globalState.is_admin}
                            >
                                <ListItemIcon>
                                    <MarketingIcon />
                                </ListItemIcon>
                                <ListItemText primary='Transactions' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/broker/position_summary' ? 'active' : ''} onClick={() => handleNavigate(`/broker/position_summary`)}
                                disabled={!(calculateIsModuleAllowed(MODULE_BROKER) || globalState.is_broker)
                                    || (globalState.is_broker && globalState?.producer_token/*Disable if impersonating*/)
                                    || !globalState.is_admin}
                            >
                                <ListItemIcon>
                                    <FinancialIcon />
                                </ListItemIcon>
                                <ListItemText primary='Position Summary' />
                            </ListItemButton>
                            <ListItemButton className={pathname == '/broker/clients' ? 'active' : ''} onClick={() => handleNavigate(`/broker/clients`)}
                                disabled={!(calculateIsModuleAllowed(MODULE_BROKER) || globalState.is_broker)
                                    || (globalState.is_broker && globalState?.producer_token/*Disable if impersonating*/)
                                    || !globalState.is_admin}
                            >
                                <ListItemIcon>
                                    <FinancialIcon />
                                </ListItemIcon>
                                <ListItemText primary='Clients' />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => toggleIsSubMenuOpen(MODULE_REPORTS)} disabled={!calculateIsModuleAllowed(MODULE_REPORTS)}>
                        <ListItemIcon>
                            <FinancialIcon />
                        </ListItemIcon>
                        <ListItemText primary='Reports' />
                        {!calculateIsModuleActive(MODULE_REPORTS) && (calculateIsSubMenuOpen(MODULE_REPORTS) ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                    <Collapse in={calculateIsSubMenuOpen(MODULE_REPORTS)}>
                        <List component="div" disablePadding>
                            <ListItemButton className={pathname == `/reports/vault/FarmsReport/${getCurrentReinsuranceYear()}` ? 'active' : ''} onClick={() => handleNavigate(`/reports/vault/FarmsReport/${getCurrentReinsuranceYear()}`)}>
                                <ListItemIcon>
                                    <FarmVaultIcon />
                                </ListItemIcon>
                                <ListItemText primary='Planted Acres by Farm' />
                            </ListItemButton>
                            <ListItemButton className={pathname == `/reports/vault/FarmTractFieldReport/${getCurrentReinsuranceYear()}` ? 'active' : ''} onClick={() => handleNavigate(`/reports/vault/FarmTractFieldReport/${getCurrentReinsuranceYear()}`)}>
                                <ListItemIcon>
                                    <FarmVaultIcon />
                                </ListItemIcon>
                                <ListItemText primary='Planted Acres by Field' />
                            </ListItemButton>
                            <ListItemButton className={pathname == `/reports/vault/AcresMapReport/${getCurrentReinsuranceYear()}` ? 'active' : ''} onClick={() => handleNavigate(`/reports/vault/AcresMapReport/${getCurrentReinsuranceYear()}`)}>
                                <ListItemIcon>
                                    <FarmVaultIcon />
                                </ListItemIcon>
                                <ListItemText primary='Acres Map' />
                            </ListItemButton>

                            {/* <ListItemButton className={pathname == `/reports/vault/InsuredRevenueByFarmReport/${getCurrentReinsuranceYear()}` ? 'active' : ''} onClick={() => handleNavigate(`/reports/vault/InsuredRevenueByFarmReport/${getCurrentReinsuranceYear()}`)}>
                                <ListItemIcon>
                                    <InsuranceIcon />
                                </ListItemIcon>
                                <ListItemText primary='Insured Revenue by Farm' />
                            </ListItemButton> */}
                        </List>
                    </Collapse>

                    {(Boolean(globalState?.is_lender) || [USER_ROLE_ADMIN].includes(globalState.user_role)) && <>
                        <ListItemButton className={pathname == '/lender/loan_origination_memo' ? 'active' : ''} onClick={() => handleNavigate(`/lender/loan_origination_memo`)} disabled={!calculateIsModuleAllowed(MODULE_FINANCIAL)}>
                            <ListItemIcon>
                                <LoanOriginationIcon />
                            </ListItemIcon>
                            <ListItemText primary='Loan Origination' />
                        </ListItemButton>
                    </>}
                    {/* {[USER_ROLE_ADMIN].includes(globalState.user_role) && <>
                        <ListItemButton onClick={() => handleNavigate(`/lender/loan_book`)}>
                            <ListItemIcon>
                                <LoanOriginationIcon />
                            </ListItemIcon>
                            <ListItemText primary='Loan Book' />
                        </ListItemButton>
                    </>} */}

                    <ListItemButton className={pathname == `/userGrant/${globalState.producer_token}` ? 'active' : ''} onClick={() => handleNavigate(`/userGrant/${globalState.producer_token}`)}
                        disabled={!([USER_ROLE_PRODUCER, USER_ROLE_ADMIN].includes(globalState.user_role) && globalState?.producer_token)}>
                        <ListItemIcon>
                            <SecurityIcon />
                        </ListItemIcon>
                        <ListItemText primary='Sharing' />
                    </ListItemButton>

                    {[USER_ROLE_ADMIN].includes(globalState.user_role) && <>
                        <ListItemButton onClick={() => toggleIsSubMenuOpen(MODULE_SYSTEM)}
                            disabled={false}
                        >
                            <ListItemIcon>
                                <MarketingIcon />
                            </ListItemIcon>
                            <ListItemText primary='System' />
                            {!calculateIsModuleActive(MODULE_SYSTEM) && (calculateIsSubMenuOpen(MODULE_SYSTEM) ? <ExpandLess /> : <ExpandMore />)}
                        </ListItemButton>

                        <Collapse in={calculateIsSubMenuOpen(MODULE_SYSTEM)}>
                            <List component="div" disablePadding>
                                <ListItemButton className={pathname == '/system/change_audit' ? 'active' : ''} onClick={() => handleNavigate(`/system/change_audit`)}
                                    disabled={false}>
                                    <ListItemIcon>
                                        <MarketingIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Change Log' />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>}
                </List>
                <Divider />
                <List>
                    {[USER_ROLE_ADMIN, USER_ROLE_AGENT].includes(globalState.user_role) &&
                        <ListItemButton className={pathname == '/account' ? 'active' : ''} onClick={() => handleNavigate(`/account`)}>
                            <ListItemIcon>
                                <MemberManagementIcon />
                            </ListItemIcon>
                            <ListItemText primary='Members' />
                        </ListItemButton>}
                    {Boolean(activeProducer?.producer_token) && <>
                        <Stack className='listInfoItem' direction='row' alignItems='center'>
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            <Box>
                                <Typography variant='caption'>
                                    Viewing Account
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: 'capitalize' }}>
                                    {activeProducer?.first_name?.toLowerCase()}
                                    <> </>
                                    {activeProducer?.middle_name?.toLowerCase()}
                                    <> </>
                                    {activeProducer?.last_name?.toLowerCase()}
                                    <> </>
                                    {activeProducer?.business_name?.toLowerCase()}
                                </Typography>
                                {(activeProducer?.producer_city_name || activeProducer?.city) &&
                                    <Typography variant='body2' color='GrayText' sx={{ textTransform: 'capitalize' }}>
                                        {activeProducer?.producer_city_name?.toLowerCase() || activeProducer?.city?.toLowerCase()}
                                        <>, </>
                                        {activeProducer?.producer_state_abbreviation?.toUpperCase() || activeProducer?.state_abbreviation?.toUpperCase()}
                                    </Typography>}
                            </Box>
                            {[USER_ROLE_ADMIN, USER_ROLE_AGENT].includes(globalState.user_role) &&
                                <Box sx={{ ml: 'auto' }} justifyContent='flex-end'>
                                    <IconButton onClick={handleActiveUserSignoutClick}>
                                        <LogoutOutlined />
                                    </IconButton>
                                </Box>}
                        </Stack>
                    </>}
                </List>

                <Divider />

                <Box sx={{ width: '100%', p: 0, m: 0, mb: '0px', marginTop: 'auto' }}>
                    <List>
                        <ListItemButton>
                            <ListItemIcon>
                                <ManageAccountsTwoTone color='success' />
                            </ListItemIcon>
                            <ListItemText primary={<>
                                <Stack display="flex" direction='row' alignItems='center' sx={{ m: 0, p: 0 }}>
                                    {!Boolean(activeAgent) &&
                                        <Typography variant="body1" sx={{ maxWidth: "170px", overflow: "clip" }}>
                                            {myUser?.name}
                                        </Typography>}
                                    {Boolean(activeAgent) &&
                                        <Typography variant='body1' sx={{ overflow: "clip", textTransform: 'capitalize' }}>
                                            {activeAgent?.first_name?.toLowerCase()}
                                            <> </>
                                            {activeAgent?.middle_name?.toLowerCase()}
                                            <> </>
                                            {activeAgent?.last_name?.toLowerCase()}
                                            <> </>
                                            {activeAgent?.business_name?.toLowerCase()}
                                        </Typography>}
                                    <Box sx={{ ml: 'auto' }} justifyContent='flex-end'>
                                        <IconButton onClick={handleLogoutClick}>
                                            <LogoutOutlined />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </>} />
                        </ListItemButton>
                        {process.env.REACT_APP_VERSION &&
                            <ListItem>
                                <ListItemText sx={{ pl: "4.5rem" }}>
                                    <Typography variant="caption" color="GrayText">
                                        v{process.env.REACT_APP_VERSION}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        }
                    </List>
                </Box>
            </Drawer>

            <Dialog open={doShowPaymentDialog} PaperProps={{ sx: { minWidth: "50vw" } }}>
                <DialogTitle>
                    Your Tilley Subscription
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} alignItems="center" flexWrap="wrap" justifyContent="space-around" sx={{ width: "100%" }}>
                        {calculateIsExpired() &&
                            <Typography>
                                Unfortunately, your Tilley subscription is expired or payment is due.
                            </Typography>}
                        {!calculateIsExpired() && calculateIsExpiringSoon() && <>
                            <Typography>
                                Your Tilley subscription expires on {(new Date(subscriptionStatus?.reNewalDate))?.toDateString()}.
                            </Typography>
                        </>}

                        <Typography>
                            Please make a secure payment now to continue your subscription.
                        </Typography>

                        {Boolean(globalState?.subscription_payment_timestamp) && <>
                            <Typography color="red">
                                If you have made a recent payment, or a bank payment is pending, please disregard this message.
                            </Typography>
                        </>}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handlePaymentLinkClick}>
                        Secure Payment
                        {isLoadingPaymentLink && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    ml: 1,
                                }}
                            />
                        )}
                        {!isLoadingPaymentLink &&
                            <EastOutlined sx={{ ml: 1 }} />
                        }
                    </Button>

                    {!calculateIsExpired() && calculateIsExpiringSoon() && <>
                        <Button variant="outlined" onClick={handleSnoozePaymentDialog}>
                            Remind Me Later
                            <CalendarMonthOutlined sx={{ ml: 1 }} />
                        </Button>
                    </>}

                    {Boolean(globalState?.subscription_payment_timestamp) && <>
                        <Button variant="outlined" onClick={handleClosePaymentDialog}>
                            Close
                            <CloseOutlined sx={{ ml: 1 }} />
                        </Button>
                    </>}
                </DialogActions>
            </Dialog>
        </>
        )
    )
}
