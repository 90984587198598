import { formatDate } from "utility/formatters";

export const SALE_COMMODITY_TYPES = ["Corn", "Soybeans", "Wheat", "Cotton", "Rice", "Sorghum", "Barley", "Oats", "Peanuts", "Sunflowers", "Canola", "Flaxseed", "Safflower", "Rye", "Millet", "Mustard Seed", "Tricale", "Cover Crops", "Other Crops"];


export const SALE_CATEGORY_CASH = "Cash";
export const SALE_CATEGORY_BASIS = "Basis";
export const SALE_CATEGORY_FUTURES_ONLY = "Futures Only / HTA";
export const SALE_CATEGORY_NO_PRICE = "No Price Established";
export const SALE_CATEGORIES = [SALE_CATEGORY_CASH, SALE_CATEGORY_BASIS, SALE_CATEGORY_FUTURES_ONLY, SALE_CATEGORY_NO_PRICE];


export const isFieldDisabled = (sale, field) => {
    const disabledConditions = {
        amount: !(sale?.category === SALE_CATEGORY_CASH || sale?.category === SALE_CATEGORY_CASH || sale?.category === SALE_CATEGORY_BASIS || sale?.category === SALE_CATEGORY_FUTURES_ONLY || sale?.category === SALE_CATEGORY_NO_PRICE),
        price: !(sale?.category === SALE_CATEGORY_CASH || sale?.category === SALE_CATEGORY_FUTURES_ONLY),
        symbol: !(sale?.category === SALE_CATEGORY_CASH || sale?.category === SALE_CATEGORY_BASIS || sale?.category === SALE_CATEGORY_FUTURES_ONLY),
        basis: !(sale?.category === SALE_CATEGORY_CASH || sale?.category === SALE_CATEGORY_BASIS),
    };
    return disabledConditions[field];
};

export const formatDeliveryPeriod = (sale) => {
    if (sale?.delivery_period_start && sale?.delivery_period_end) {
        // if Year is the same, only show end year
        if ((new Date(sale.delivery_period_start)).getFullYear() === (new Date(sale.delivery_period_end)).getFullYear()) {
            // Remove year from start date
            const startDate = formatDate(sale.delivery_period_start).replace(/\/\d{4}/, "");
            return `${startDate} - ${formatDate(sale.delivery_period_end)}`;
        }
        else {
            return `${formatDate(sale.delivery_period_start)} - ${formatDate(sale.delivery_period_end)}`;
        }
    }
    return "";
}