import { ApiHelper as ApiHelperDatanac, USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper'
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { AddCircleOutline } from '@mui/icons-material'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, Dialog, Divider, Grid, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DataGrid } from '@mui/x-data-grid'
import { AppContext } from 'App'
import { ApiHelper, UsersApiHelper, _sessionManager } from 'api/ApiHelper'
import { getUnitOfMeasureAbbreviation } from 'components/Budget/BudgetService'
import { BUDGET_STATUS_ACTIVE, getBudgetYears } from 'components/Budget/helpers'
import DataFormSelect from 'components/Core/DataFormSelect'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { getUniqueCropPractices } from 'components/Vault/Farm/helpers'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { formatNumber, formatPercentage, formatPrice, formatProducerName } from 'utility/formatters'
import { SalesEditDialog } from './SalesEditDialog'
import { SALE_CATEGORY_CASH, formatDeliveryPeriod } from './SalesHelper'

export default function RiskSummaryWorkspace() {

    const { globalState } = useContext(AppContext);
    const [futures, setFutures] = useState(null);


    const {
        producer_token,
        defaultYear,
        default_commodity_name
    } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const [isLoadingCurrent, setIsLoadingCurrent] = useState(false);

    const [currentYear, setCurrentYear] = useState(defaultYear ? defaultYear : getCurrentReinsuranceYear());

    // --- --- ---

    const [positionSummaryData, setPositionSummaryData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const _loadPositionSummaryData = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoading(true);

                const _positionSummaryFilter = {
                    agent_token: globalState?.agent_token,
                    year: currentYear,
                    producer_token: producer_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _positionSummaryData = await UsersApiHelper.users_search("PositionSummary", _positionSummaryFilter, _apiConfig);
                let _filteredPositionSummaryData = _positionSummaryData;
                if (filter?.commodity_name?.length) {
                    _filteredPositionSummaryData = _positionSummaryData
                        .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                }

                setPositionSummaryData(_filteredPositionSummaryData);
                setIsLoading(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoading(true);

                const _positionSummaryFilter = {
                    year: currentYear,
                    producer_token: producer_token,
                };
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _positionSummaryData = await UsersApiHelper.users_search("PositionSummary", _positionSummaryFilter, _apiConfig);
                let _filteredPositionSummaryData = _positionSummaryData;
                if (filter?.commodity_name?.length) {
                    _filteredPositionSummaryData = _positionSummaryData
                        .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                }

                setPositionSummaryData(_filteredPositionSummaryData);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            setPositionSummaryData(null);
            handleError(err);
        }
    }

    // ---

    const [salesByCategory, setSalesByCategory] = useState(null);
    const [sales, setSales] = useState(null);
    const [isLoadingSalesByCategory, setIsLoadingSalesByCategory] = useState(false);
    const _loadSalesByCategory = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoading(true);

                const _positionSummaryDataFilter = {
                    agent_token: globalState?.agent_token,
                    year: currentYear,
                    producer_token: producer_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _positionSummaryData = await UsersApiHelper.users_search("PositionSummarySalesByCategory", _positionSummaryDataFilter, _apiConfig);
                let _filteredPositionSummaryData = _positionSummaryData;
                if (filter?.commodity_name?.length) {
                    _filteredPositionSummaryData = _positionSummaryData
                        .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                }

                setSalesByCategory(_filteredPositionSummaryData);
                setIsLoadingSalesByCategory(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoadingSalesByCategory(true);

                const _positionSummaryDataFilter = {
                    year: currentYear,
                    producer_token: producer_token,
                };
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _positionSummaryData = await UsersApiHelper.users_search("PositionSummarySalesByCategory", _positionSummaryDataFilter, _apiConfig);
                let _filteredPositionSummaryData = _positionSummaryData;
                if (filter?.commodity_name?.length) {
                    _filteredPositionSummaryData = _positionSummaryData
                        .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                }

                setSalesByCategory(_filteredPositionSummaryData);
                setIsLoadingSalesByCategory(false);
            }
        } catch (err) {
            setIsLoadingSalesByCategory(false);
            setSalesByCategory(null);
            handleError(err);
        }
    }

    const getSalesRowsByCommodity = useCallback((commodity_name) => {
        if (sales?.length) {
            return sales.filter(s => s.commodity_name == commodity_name);
        } else {
            return [];
        }
    }, [sales]);

    const _loadSales = async () => {
        try {
            // Get Sales:
            const _salesFilter = {
                crop_year: currentYear,
                producer_token: producer_token
            };
            const _sales = await UsersApiHelper.users_search("Sale", _salesFilter, globalState);
            let _filteredSales = _sales;

            // Order by trade_date desc
            _filteredSales = (_filteredSales.sort((a, b) => new Date(b.trade_date) - new Date(a.trade_date)));
            // Add dummy fields:
            _filteredSales = _filteredSales.map(s => {
                if (s?.category == SALE_CATEGORY_CASH) {
                    s.price_cash = (s?.price || 0) + (s?.basis || 0);
                } else {
                    s.price_cash = null;
                }
                return s;
            });

            setSales(_filteredSales);
            setIsLoading(false);
        } catch (err) {
            setSales(null);
            setIsLoading(false);
            handleError(err);
        }
    };

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        return date.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' });
        // Example output: "Aug 23, 2024"
    };

    const formatDateTime = (isoDateString) => {
        const date = new Date(isoDateString);
        // Example output: "Aug 23, 4:00 pm"
        return date.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' });
    };

    const sales_columns = useMemo(() => [
        { field: 'financial_institution_name', headerName: 'Buyer', flex: 1 },
        { field: 'category', headerName: 'Category', flex: 1 },
        { field: 'amount', headerName: 'Qty', flex: 1 },
        { field: 'price', headerName: 'Price', flex: 1 },
        { field: 'Fees', headerName: 'Fees', flex: 1 },
        {
            field: 'delivery_period_start',
            headerName: 'Delivery',
            flex: 1,
            valueFormatter: (params) =>
                (sales?.length) ? formatDeliveryPeriod(sales?.find(s => s.id == params?.id)) : null
        },
        { field: 'notes', headerName: 'Notes', flex: 1 },
        {
            field: 'trade_date',
            headerName: 'Sale Date',
            flex: 1,
            valueGetter: (params) => {
                return formatDate(params.row.trade_date); // Convert the ISO date to human-readable format
            }
        },
        { field: 'revenue', headerName: 'Revenue', flex: 1 },
    ], [sales]);

    const [transactionHistory, setTransactionHistory] = useState(null);
    const _loadTransactionHistory = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoading(true);

                const _transactionHistoryFilter = {
                    agent_token: globalState?.agent_token,
                    year: currentYear,
                    producer_token: producer_token,
                    is_posted: true,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const transactionHistory = await UsersApiHelper.selectMarketTransactionHistory(_transactionHistoryFilter, _apiConfig);

                // Assign crop_profile = formatDefaultCropProfile(params?.row)
                transactionHistory.forEach(hist => hist.crop_profile = hist.crop_profile || formatDefaultCropProfile(hist));

                let _filteredTransactions = transactionHistory;
                // if (filter?.commodity_name?.length) {
                //     _filteredTransactions = transactionHistory
                //         .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                // }

                // Order by trade_date ASC
                _filteredTransactions = (_filteredTransactions.sort((a, b) => new Date(a.trade_date) - new Date(b.trade_date)));

                setTransactionHistory(_filteredTransactions);
                setIsLoading(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoading(true);

                const _transactionHistoryFilter = {
                    year: currentYear,
                    producer_token: producer_token,
                    is_posted: true,
                };
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const transactionHistory = await UsersApiHelper.selectMarketTransactionHistory(_transactionHistoryFilter, _apiConfig);

                // Assign crop_profile = formatDefaultCropProfile(params?.row)
                transactionHistory.forEach(hist => hist.crop_profile = hist.crop_profile || formatDefaultCropProfile(hist));

                let _filteredTransactions = transactionHistory;

                if (filter?.commodity_name_list?.length) {
                    _filteredTransactions = transactionHistory
                        .filter(obj => filter?.commodity_name_list?.includes(obj.commodity_name));
                }

                if (filter?.search_contract_number) {
                    _filteredTransactions = _filteredTransactions
                        .filter(obj => obj.contract_number?.toLowerCase().includes(filter?.search_contract_number?.toLowerCase()));
                }

                // Order by trade_date ASC
                _filteredTransactions = (_filteredTransactions.sort((a, b) => new Date(a.trade_date) - new Date(b.trade_date)));

                setTransactionHistory(_filteredTransactions);
                setIsLoading(false);
            }
        } catch (err) {
            // setIsLoadingTransactionHistory(false);
            // setTransactionHistory(null);
            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    const getTransactionHistoryRowsByCommodity = useCallback((commodity_name) => {
        if (transactionHistory?.length) {
            return transactionHistory.filter(s => s.commodity_name == commodity_name);
        } else {
            return [];
        }
    }, [transactionHistory]);

    // ---

    const formatDefaultCropProfile = (row) => {
        if (row?.is_posted == null) {
            if (row?.commodity_reference_month && row?.symbol_month && row?.symbol_year && row?.commodity_name) {
                let _cropProfileYear = row?.symbol_year;
                if (row?.symbol_month < row?.commodity_reference_month) {
                    _cropProfileYear--;
                }
                const _cropProfile = _cropProfileYear + " - " + row?.commodity_name;
                return _cropProfile;
            } else if (row?.symbol_month && row?.symbol_year && row?.commodity_name) {
                return row?.symbol_year + " - " + row?.commodity_name;
            } else {
                return null;
            }
        } else if (row?.crop_profile_year && row?.crop_profile_commodity_name) {
            return row?.crop_profile_year + " - " + row?.crop_profile_commodity_name;
        } else {
            return row?.crop_profile || null;
        }
    }

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1); // JavaScript months are 0-indexed
        return date.toLocaleString('default', { month: 'short' }); // Get short month name (e.g., "Dec")
    };

    const columns = useMemo(() => [
        {
            field: "is_open",
            headerName: "Open",
            valueFormatter: (params) => {
                return params?.value ? "Open" : "Closed";
            }
        },
        {
            field: 'commodity_name',
            headerName: 'Commodity',
            flex: 1
        },
        {
            field: 'buy_sell_code',
            headerName: 'Position',
            valueGetter: (params) => {
                if (params.row.buy_sell_code == 1) {
                    return 'Buy';
                } else if (params.row.buy_sell_code == 2) {
                    return 'Sell';
                }
            },
            flex: 1
        },
        { field: 'quantity', headerName: 'Qty', flex: 1 },
        {
            field: 'symbol_month',
            headerName: 'Month/Year',
            flex: 1,
            valueGetter: (params) => {
                const monthNumber = params.row.symbol_month;
                const monthName = getMonthName(monthNumber);
                // Return 2-digit year
                return `${monthName} - ${params.row.symbol_year?.toString().slice(-2)}`;
            }
        },
        {
            field: 'trade_price',
            headerName: 'Price',
            flex: 1,
            valueFormatter: (params) => {
                return formatPrice(params?.value, 4);
            },
            //Right align
            headerAlign: 'right',
            align: 'right',
        },
        { field: 'Current_Price', headerName: 'Current Price', flex: 1 },
        {
            field: 'market_value',
            headerName: 'Gain/Loss',
            flex: 1,
            valueFormatter: (params) => {
                return formatPrice(params?.value, 0);
            },
            //Right align
            headerAlign: 'right',
            align: 'right',
        },
        {
            field: 'trade_date',
            headerName: 'Date',
            flex: 1,
            valueGetter: (params) => {
                return formatDate(params.row.trade_date); // Convert the ISO date to human-readable format
            }
        },
    ], []);


    // ---

    const reportData = useMemo(() => {
        if (positionSummaryData?.length && !salesByCategory?.length) {
            return positionSummaryData;
        } else if (positionSummaryData?.length && salesByCategory?.length) {
            const _reportData = [...positionSummaryData];
            for (const ps of _reportData) {
                const _salesByCategory = salesByCategory.filter(sbc => sbc.commodity_name == ps.commodity_name);
                ps.salesByCategory = _salesByCategory || [];

                for (const currentSaleByCategory of ps.salesByCategory) {
                    // Calculate percentage of production
                    if (Boolean(ps.production)) {
                        currentSaleByCategory.percentage_of_production = (currentSaleByCategory.amount / ps.production);
                    } else {
                        currentSaleByCategory.percentage_of_production = null;
                    }
                }
            }
            return _reportData;
        } else {
            return null;
        }
    }, [positionSummaryData, salesByCategory]);

    /// --- --- ---

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const [budgetCountyPractices, setBudgetCountyPractices] = useState(null);
    const [isLoadingBudgetCountyPractices, setIsLoadingBudgetCountyPractices] = useState(true);
    const loadBudgetCountyPractices = async () => {
        try {
            const _budgetFilter = {
                // state_name, county_name,
                // commodity_name, type_name, practice_name,
                year: currentYear,
                status: BUDGET_STATUS_ACTIVE,
                is_active: true,
                producer_token: producer_token
            }
            // if (commodity_name) { _budgetFilter.commodity_name = commodity_name; }
            // if (type_name) { _budgetFilter.type_name = type_name; }
            // if (practice_name) { _budgetFilter.practice_name = practice_name; }

            const _allCountyPractices = await UsersApiHelper.selectBudgetCountyPractices(_budgetFilter, globalState);
            setBudgetCountyPractices(_allCountyPractices);

            setIsLoadingBudgetCountyPractices(false);
        } catch (err) {
            setIsLoadingBudgetCountyPractices(false);
            setBudgetCountyPractices([]);
            handleError(err);
        }
    }

    useEffect(() => {
        loadBudgetCountyPractices();
    }, [globalState?.producer_token])

    // ---

    const selectFuturesByBudget = async (budget) => {
        if (budget.year
            && budget.state_name && budget.county_name
            && budget.commodity_name
            && budget.type_name && budget.practice_name
        ) {
            const futuresParameters = {
                reinsurance_year: currentYear,
                state_name: budget.state_name + "  ",
                county_name: budget.county_name,
                commodity_name: budget.commodity_name,
                type_name: budget.type_name,
                practice_name: budget.practice_name
            };
            const _futures = await ApiHelper.callSmociObject("data/DATANAC", "Markets", "Futures", "Search", futuresParameters, globalState);

            return _futures;
        } else {
            console.warn(budget);
            throw "Invalid budget parameters.";
        }
    }

    // ---

    const loadUniqueCropPractices = async () => {
        try {
            // Get unique:
            const _uniqueCropPractices = getUniqueCropPractices(budgetCountyPractices);

            const _loadedCropPractices = [];
            for (const cp of _uniqueCropPractices) {
                const _compareBudget = (cp?.children?.length && cp.children[0]);
                const futures = await selectFuturesByBudget({
                    year: currentYear,
                    ..._compareBudget
                });
                if (futures?.length) {
                    cp.futures = futures;
                } else {
                    cp.futures = null;
                }

                cp.unit_of_measure_abbreviation = await getUnitOfMeasureAbbreviation({
                    reinsurance_year: currentYear,
                    year: currentYear,
                    ..._compareBudget
                }) || "";

                _loadedCropPractices.push(cp);
            }

            setUniqueCropPractices(_loadedCropPractices);
        } catch (err) {
            setUniqueCropPractices([]);
            handleError(err);
        }
    };

    const [uniqueCropPractices, setUniqueCropPractices] = useState(null);
    useEffect(() => {
        if (budgetCountyPractices?.length > 0 && uniqueCropPractices == null) {
            loadUniqueCropPractices();
        }
    }, [budgetCountyPractices])

    //useCallback to cache getUnitOfMeasureAbbreviation
    const _getUnitOfMeasureAbbreviation = useCallback((countyPractice) => {
        // Find in uniqueCropPractices:
        const _uniqueCropPractice = uniqueCropPractices?.find(cp =>
            cp.commodity_name == countyPractice.commodity_name
        );
        if (_uniqueCropPractice) {
            return _uniqueCropPractice.unit_of_measure_abbreviation;
        } else {
            return "";
        }
    }, [uniqueCropPractices]);

    //useCallback to cache getUnitOfMeasureAbbreviation
    const _getFuturesByCountyPractice = useCallback((countyPractice) => {
        // Find in uniqueCropPractices:
        const _uniqueCropPractice = uniqueCropPractices?.find(cp =>
            cp.commodity_name == countyPractice.commodity_name
        );
        if (_uniqueCropPractice?.futures?.length) {
            return _uniqueCropPractice.futures[0];
        } else {
            return null;
        }
    }, [uniqueCropPractices]);

    // --- --- ---

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        commodity_name: default_commodity_name ? [default_commodity_name] : null,
    });

    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    useEffect(() => {
        if (currentYear) {
            _loadPositionSummaryData();
            _loadSalesByCategory();
            _loadTransactionHistory();
            _loadSales();
        }
    }, [currentYear, filter]);

    const [editingSale, setEditingSale] = useState(null);

    const handleClickNewSale = async () => {
        setEditingSale({
            producer_token: globalState.producer_token,
            crop_year: currentYear,
            trade_date: new Date(),
        });
    }

    const isValid = () => {
        return true;
    }

    const handleChange = async (sale) => {
        try {
            const api = new ApiHelperDatanac({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });

            if (!sale.producer_token) {
                sale.producer_token = globalState.producer_token;
            }
            if (!sale.crop_year) {
                sale.crop_year = currentYear;
            }

            await api.callPost("marketing/Sale", sale, globalState);
        } catch (err) {
            handleError(err);
        }
    }
    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title={'Position Summary' + (reportData?.length ? " - " + formatProducerName(reportData[0] || "") : "")}
                breadCrumbs={[{
                    title: 'Marketing',
                    path: producer_token ? '/broker/position_summary' : '',
                    icon: <FarmVaultIcon />
                }]}
            />

            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton marketing-position'>
                        <DataFormSelect
                            value={filter.commodity_name || []}
                            name="commodity_name"
                            isRequired={false}
                            onChange={filterHandleChangeState}
                            objectName="Commodity"
                            itemFilter={{ reinsurance_year: String(currentYear) }}
                            itemGetKey={(item) => item.commodity_name}
                            itemGetLabel={(item) => item.commodity_name}
                            doFetch={currentYear != null}
                            isLoading={false}
                            multiple={true}
                            limitTags={isSmall ? 2 : 3}
                        />
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {popupState => (
                                <>
                                    <Button variant="outlined" {...bindTrigger(popupState)} className='year-filter'>
                                        {currentYear} <ArrowDropDownOutlined />
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        {getBudgetYears()?.map((year, index) => (
                                            <MenuItem key={index} onClick={() => {
                                                setCurrentYear(year);
                                                popupState.close();
                                            }}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </PopupState>
                        {isLoadingCurrent && <CircularProgress />}
                    </Stack>
                </Grid>
            </Grid>

            <Typography variant='h6'>
                {Boolean(reportData?.length) && formatProducerName(reportData[0])}
            </Typography>

            {Boolean(reportData?.length) && reportData?.map((currentCountyPractice, idx) => (
                <div key={idx}>
                    <h2>{currentCountyPractice.commodity_name}</h2>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" className='marketing-table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Acres</TableCell>
                                    <TableCell>Yield (avg)</TableCell>
                                    <TableCell>Production</TableCell>
                                    <TableCell>Insured Guarantee</TableCell>
                                    <TableCell>Hedged</TableCell>
                                    <TableCell>Board</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.reported_acreage, 0)}
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.yield_average, 0)}
                                        {" " + _getUnitOfMeasureAbbreviation(currentCountyPractice)?.toLowerCase()}
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.production, 0)}
                                        {" " + _getUnitOfMeasureAbbreviation(currentCountyPractice)?.toLowerCase()}
                                        <br /><br />
                                        {!Boolean(currentCountyPractice?.production)
                                            ? "-- %"
                                            : formatPercentage(
                                                currentCountyPractice?.amount / currentCountyPractice?.production
                                                , 0)}
                                        {" "} sold
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.production_guarantee)}
                                        {" " + _getUnitOfMeasureAbbreviation(currentCountyPractice)?.toLowerCase()}
                                        <br /><br />
                                        {!Boolean(currentCountyPractice?.production_guarantee)
                                            ? "-- %"
                                            : formatPercentage((
                                                currentCountyPractice?.sales_amount / currentCountyPractice?.production_guarantee
                                                , 0))} sold
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.unit_quantity_hedge)}
                                        {" " + _getUnitOfMeasureAbbreviation(currentCountyPractice)?.toLowerCase()}
                                        <br /><br />
                                        {!Boolean(currentCountyPractice?.production)
                                            ? "-- %"
                                            : formatPercentage(
                                                currentCountyPractice?.unit_quantity_hedge / currentCountyPractice?.production
                                                , 0)} of production
                                    </TableCell>
                                    <TableCell>
                                        {_getFuturesByCountyPractice(currentCountyPractice) &&
                                            <>
                                                {_getFuturesByCountyPractice(currentCountyPractice)?.harvest_symbol}
                                                <br /><br />
                                                {formatPrice(_getFuturesByCountyPractice(currentCountyPractice)?.harvest_price_close, 2)}
                                                {/* <br /><br />
                                                {formatDateTime(_getFuturesByCountyPractice(currentCountyPractice)?.harvest_trade_timestamp)} */}
                                            </>}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Divider />

                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" className='marketing-table marketing-table-second'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Per Unit</TableCell>
                                    <TableCell>Per Acre</TableCell>
                                    <TableCell>Gross</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>Current</TableCell>
                                    <TableCell>{formatPrice(currentCountyPractice.budget_breakeven, 4)}</TableCell>
                                    <TableCell>{formatPrice(currentCountyPractice.budget_total / currentCountyPractice?.reported_acreage, 0)}</TableCell>
                                    <TableCell>{formatPrice(currentCountyPractice.budget_total, 0)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Divider />

                    <h3 className='cash-summary'>Cash Summary - {currentCountyPractice.commodity_name || ''}</h3>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" className='marketing-table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sale type</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>% Prod.</TableCell>
                                    <TableCell>Price / Unit</TableCell>
                                    <TableCell>Revenue</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(() => {
                                    let totalQty = 0;
                                    let totalProd = 0;
                                    let totalBudget = 0;
                                    let totalRevenue = 0;

                                    let totalPercentageOfProduction = 0;

                                    return (
                                        <>
                                            {Boolean(currentCountyPractice?.salesByCategory?.length) &&
                                                currentCountyPractice?.salesByCategory?.map((ps, idx) => {
                                                    // Accumulate totals
                                                    totalQty += ps.amount;
                                                    totalBudget += ps.price;
                                                    totalRevenue += ps.revenue;

                                                    if (currentCountyPractice?.production) {
                                                        totalPercentageOfProduction = (currentCountyPractice?.production == 0) ? null : (totalQty / currentCountyPractice?.production);
                                                    } else {
                                                        totalPercentageOfProduction = null;
                                                    }

                                                    return (
                                                        <TableRow key={idx} hover role="checkbox" tabIndex={-1}>
                                                            <TableCell>{ps.category}</TableCell>
                                                            <TableCell>{formatNumber(ps.amount)}</TableCell>
                                                            <TableCell>{!Boolean(ps.percentage_of_production) ? "-- %" : formatPercentage(ps.percentage_of_production, 0)}</TableCell>
                                                            <TableCell>{formatPrice(ps.price_per_unit, 4)}</TableCell>
                                                            <TableCell>{formatPrice(ps.revenue, 0)}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            <TableRow hover role="checkbox" tabIndex={-1} className='cash-summary-total'>
                                                <TableCell>Total</TableCell>
                                                <TableCell>{formatNumber(totalQty)}</TableCell>
                                                <TableCell>{!Boolean(totalPercentageOfProduction)
                                                    ? "-- %" : formatPercentage(totalPercentageOfProduction, 0)}</TableCell>
                                                <TableCell>{!Boolean(totalQty)
                                                    ? null : formatPrice(totalRevenue / totalQty, 4)}</TableCell>
                                                <TableCell>{formatPrice(totalRevenue, 0)}</TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })()}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Divider />

                    <h3 className='cash-summary'>Cash Sales</h3>
                    <DataGrid
                        rows={getSalesRowsByCommodity(currentCountyPractice?.commodity_name) || []}
                        columns={sales_columns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: isMobile ? 5 : 10 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        autoHeight
                    />

                    <Divider />

                    <h3 className='cash-summary'>Futures & Options</h3>
                    <DataGrid
                        rows={getTransactionHistoryRowsByCommodity(currentCountyPractice?.commodity_name) || []}
                        columns={columns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: isMobile ? 5 : 10 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        autoHeight
                    />
                </div>
            ))}

            <Divider />

            <WorkspaceActionBar content={<>
                <Box>
                    <Button className='add-sale' variant="contained" onClick={handleClickNewSale} disabled={!isValid()}>
                        <AddCircleOutline sx={{ mr: 1 }} />
                        Add Sale
                    </Button>
                </Box>
            </>} />


            <Dialog open={Boolean(editingSale != null)}>
                <SalesEditDialog
                    producer_token={producer_token}//Override producer_token
                    onChange={handleChange} onClose={() => { setEditingSale(null); setIsLoading(true); _loadSales(); }}
                    sale={editingSale}
                    crop_year={currentYear} />
            </Dialog>
        </>
    );
}