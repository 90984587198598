import { Skeleton, useMediaQuery, useTheme } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { RmaApiHelper } from '../../api/ApiHelper'
import _ from 'lodash'

export default function DataFormSelect({
    name,
    value,
    onChange,
    objectName,
    objectNameLabel,
    itemFilter,
    itemSorter,
    itemGetKey,
    itemGetLabel,
    doFetch,

    values,

    isRequired,
    doAllowEmptyElement = false,
    disabled,

    doShowLabel,

    multiple,
    limitTags,

    isLoading
}) {
    if (isRequired == null) {
        isRequired = true;
    }

    const [selectedValue, setSelectedValue] = useState(value || '');
    const [_values, setValues] = useState(values || []);

    const [isEdited, setIsEdited] = useState(false);

    const theme = useTheme();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    useEffect(() => {
        if (doFetch) {
            RmaApiHelper.selectObject(objectName, itemFilter, {}).then(
                (selectObjectValues) => {
                    let uniqueValues = selectObjectValues;
                    if (itemGetKey || itemGetLabel) {
                        uniqueValues = uniqueValues
                            .map((currentItem) => {
                                return {
                                    ...currentItem,
                                    key: itemGetKey ? itemGetKey(currentItem) : currentItem?.key,
                                    value: itemGetLabel ? itemGetLabel(currentItem) : currentItem?.value,
                                    uniqueKey: ((itemGetKey && itemGetKey(currentItem)) || "")
                                        + "|"
                                        + (itemGetLabel && itemGetLabel(currentItem)) || ""
                                }
                            });
                        uniqueValues = _.uniqBy(uniqueValues, 'uniqueKey');
                    }

                    if (itemSorter && uniqueValues && uniqueValues.length) {
                        uniqueValues = uniqueValues.sort(itemSorter);
                    }
                    
                    setValues(uniqueValues);
                }
            );
        }
    }, [doFetch, itemFilter])

    useEffect(() => {
        setSelectedValue(value || '');
    }, [value])

    const handleOnBlur = () => {
        setIsEdited(true);
    }

    const handleSelectOnChange = (event) => {
        handleOnBlur();

        setSelectedValue(event?.target?.value || '');

        const newEvent = event;
        newEvent.target.name = name;
        onChange && onChange(newEvent, event?.target?.value || '');
    }

    const getIsError = () => {
        if (isRequired) {
            return (!value)
        }
    }

    const getErrorText = () => {
        if (getIsError() && isEdited) {
            return "Required";
        }
    }

    return (
        <>
            <FormControl fullWidth className='data-form'>
                {isLoading == true && <>
                    <Skeleton variant='rectangular' width="100%" sx={{ marginTop: "16px", height: "50px" }} />
                </>}

                {(isSmall && !multiple) && !isLoading && <>
                    <InputLabel id='selectLabel'>{doShowLabel !== false && (objectNameLabel || objectName)}</InputLabel>
                    <Select
                        labelId='selectLabel'
                        value={(selectedValue?.trim && selectedValue?.trim()) || selectedValue || ''}
                        multiple={multiple}
                        name={name}
                        onChange={handleSelectOnChange}
                        label={objectNameLabel || objectName}
                    >
                        {doAllowEmptyElement
                            && <MenuItem key='' value={''}></MenuItem>}
                        {_values && _values?.map((currentItem, index) => {
                            const labelText = itemGetLabel ? itemGetLabel(currentItem) : currentItem?.value;
                            return <MenuItem key={index} value={labelText?.trim()}>{labelText?.trim()}</MenuItem>
                        })}
                    </Select>
                </>}

                {(!isSmall || multiple) && !isLoading &&
                    <Autocomplete
                        disablePortal
                        className='autoComplete'
                        disabled={disabled}
                        sx={{ width: "100%", }}
                        name={name}
                        value={(value?.trim && value?.trim()) || value || null}
                        multiple={multiple}
                        limitTags={limitTags}
                        disableCloseOnSelect={multiple && !isSmall}
                        options={_values && _values?.map((currentItem, index) => {
                            // Bind primitives:
                            return itemGetLabel ? itemGetLabel(currentItem)?.trim() : currentItem?.value
                        })
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={doShowLabel !== false && (objectNameLabel || objectName)}
                                inputProps={{
                                    ...params.inputProps,
                                    id: null,
                                    autoComplete: "new-password",
                                    form: {
                                        autoComplete: "off"
                                    },
                                    m: 0, p: 0,
                                }}
                                sx={{ m: 0, p: 0, }}
                                error={getIsError()}
                                helperText={getErrorText()}
                                autoComplete='new-password'
                            />
                        }
                        onChange={(event, value) => {
                            ////console.log(value);
                            const newEvent = event;
                            newEvent.target.name = name;
                            ////console.log(newEvent);
                            onChange && onChange(newEvent, value || '');
                        }}
                        onBlur={handleOnBlur}
                        onFocus={(e) => e.target.select()}
                    />
                }

            </FormControl>
        </>
    )
}
